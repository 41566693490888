import React, { /* useContext, */ useMemo } from 'react';
import countryList from 'react-select-country-list';

// import { FormContext } from '../context';

// import Select from './select';

import loadComponents from '../../Loadable';

// const Label = loadComponents('form-label');
const Select = loadComponents('form-select');

export default function Country({ field }) {
  // const { handleChange } = useContext(FormContext);
  const countries = useMemo(() => countryList().getData(), []);

  const options = [];
  countries.forEach(option => {
    options.push({ value: option.value, option: option.label });
  });

  const data = {
    ...field,
    options,
  };
  return <Select field={data} />;
}
